import React from 'react';
import './styles/Title.css';
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa'; // Importing Twitter and Telegram icons

const Title = () => {
  return (
    <div className="title">
      {/* Title and Video Section */}
      <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
        <video className="title-video" autoPlay loop muted playsInline>
          <source src="/images/100xsticker.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </a>

      {/* Social Media Links */}
      <div className="social-links">
        <a href="https://x.com/GroundTuah" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTwitter size={32} /> {/* Twitter icon */}
        </a>
        <a href="https://t.me/HawkGroundTuah" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTelegramPlane size={32} /> {/* Telegram icon */}
        </a>
      </div>
    </div>
  );
};

export default Title;