import React, { useEffect } from 'react';
import Title from './components/Title';
import Home from './components/Home';
import Buy from './components/Buy';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  useEffect(() => {
    // Generate Flashes
    const flashCount = 100; // Number of flashes to generate
    const twinkleContainer = document.querySelector('.twinkle-container');

    for (let i = 0; i < flashCount; i++) {
      const flash = document.createElement('div');
      flash.classList.add('flash');

      // Randomize position, size, and animation duration
      const size = Math.random() * 3 + 2;  // Random size between 2px and 5px
      flash.style.left = `${Math.random() * 100}vw`; // Random horizontal position
      flash.style.top = `${Math.random() * 500}vh`; // Random vertical position (between 0 and 100% of viewport height)
      flash.style.width = `${size}px`;
      flash.style.height = `${size}px`;
      flash.style.animationDuration = `${Math.random() * 1 + 0.5}s`; // Flash duration between 0.5s to 1s

      // Add the flash to the container
      twinkleContainer.appendChild(flash);
    }
  }, []);

  return (
    <div className="App">
      {/* Container for flashes */}
      <div className="twinkle-container"></div>
      <Home />
      <Title />
      <Buy />
      <Footer />
    </div>
  );
};

export default App;