// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  position: relative; /* Make sure we can position the flashes correctly */
  overflow: hidden; /* Hide the flashes that go out of the view */
}

.twinkle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Full height of the page */
  pointer-events: none;
}

/* Flash Effect */
.flash {
  position: absolute;
  animation: flashEffect 1s ease-in-out infinite;
  opacity: 0;
  border-radius: 50%;
  pointer-events: none;
}

.flash:nth-child(odd) {
  background-color: #eb7de7; /* Pink */
}

.flash:nth-child(even) {
  background-color: #87CEEB; /* Light blue */
}

/* Flashing Effect Animation */
@keyframes flashEffect {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@media (max-width: 768px) {
  .flash {
    animation-duration: 1s; /* Flash effect duration */
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,kBAAkB,EAAE,oDAAoD;EACxE,gBAAgB,EAAE,6CAA6C;AACjE;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY,EAAE,4BAA4B;EAC1C,oBAAoB;AACtB;;AAEA,iBAAiB;AACjB;EACE,kBAAkB;EAClB,8CAA8C;EAC9C,UAAU;EACV,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB,EAAE,SAAS;AACtC;;AAEA;EACE,yBAAyB,EAAE,eAAe;AAC5C;;AAEA,8BAA8B;AAC9B;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,sBAAsB,EAAE,0BAA0B;EACpD;AACF","sourcesContent":["/* App.css */\n.App {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  position: relative; /* Make sure we can position the flashes correctly */\n  overflow: hidden; /* Hide the flashes that go out of the view */\n}\n\n.twinkle-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%; /* Full height of the page */\n  pointer-events: none;\n}\n\n/* Flash Effect */\n.flash {\n  position: absolute;\n  animation: flashEffect 1s ease-in-out infinite;\n  opacity: 0;\n  border-radius: 50%;\n  pointer-events: none;\n}\n\n.flash:nth-child(odd) {\n  background-color: #eb7de7; /* Pink */\n}\n\n.flash:nth-child(even) {\n  background-color: #87CEEB; /* Light blue */\n}\n\n/* Flashing Effect Animation */\n@keyframes flashEffect {\n  0% {\n    opacity: 0;\n    transform: scale(0.5);\n  }\n  50% {\n    opacity: 1;\n    transform: scale(1);\n  }\n  100% {\n    opacity: 0;\n    transform: scale(0.5);\n  }\n}\n\n@media (max-width: 768px) {\n  .flash {\n    animation-duration: 1s; /* Flash effect duration */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
