import React from 'react';
import './styles/Buy.css';

const Buy = () => {
  return (
    <section id="buy" className="buy">
      <div className="buy-content">
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <img src="/images/fancycarsticker.webp" alt="Buy Sticker" className="buy-image" />
        </a>
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <img src="/images/eatsticker.webp" alt="Buy Sticker" className="buy-image" />
        </a>
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <img src="/images/buyyellsticker.webp" alt="Buy Sticker" className="buy-image" />
        </a>
      </div>
    </section>
  );
};

export default Buy;