// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buy {
  padding: 2rem 1rem;
  text-align: center;
}

.buy-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Add spacing between the text and the image */
}

.buy h2 {
  font-size: 2rem;
  color: #eb7de7;
  margin-left: 10%;
}

.buy-image {
  width: 100px; /* Adjust the size of the image */
  height: auto;
}

.buy p {
  font-size: 1.2rem;
  color: #87CEEB;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Buy.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS,EAAE,+CAA+C;AAC5D;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY,EAAE,iCAAiC;EAC/C,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".buy {\n  padding: 2rem 1rem;\n  text-align: center;\n}\n\n.buy-content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem; /* Add spacing between the text and the image */\n}\n\n.buy h2 {\n  font-size: 2rem;\n  color: #eb7de7;\n  margin-left: 10%;\n}\n\n.buy-image {\n  width: 100px; /* Adjust the size of the image */\n  height: auto;\n}\n\n.buy p {\n  font-size: 1.2rem;\n  color: #87CEEB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
