import React from 'react';
import './styles/Home.css';

const Home = () => {
  return (
    <div className="title-container">
      {/* Fancy Car Sticker */}
      <div className="hawk-container">
        <img src="/images/hauksticker.webp" alt="Hawk Sticker" className="hawk" />
      </div>
            {/* Title and Video Section */}
      <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
        <video className="title-video" autoPlay loop muted playsInline>
          <source src="/images/buysticker.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </a>
      <h1>
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <span className="text-blue">Ground</span>
          <span className="text-white">Hawk</span>
        </a>
      </h1>
      {/* Contract Address under the Title */}
      <h1 className="contract-address">
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <span className="text-blue">C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon</span>
        </a>
      </h1>
      <section id="home" className="home">
        <div className="box">
          <div className="image-left">
            <video className="drool-image" autoPlay loop muted playsInline>
              <source src="/images/droolsticker.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          
          <div className="text-content">
            <p><strong>GroundHawk’s</strong> not just any ordinary groundhog, <strong>$HAWK</strong> is a beast and she's here to <strong>spit on that thang</strong> like the legend herself. 💦</p>
            <p>She ain’t polite, she's just here to leave the world shook and wet. Get your <strong>$HAWK</strong> today!</p>
          </div>
          
          <div className="image-right">
            <img src="/images/logo.jpg" alt="Logo" className="logo-image" />
          </div>
        </div>
      </section>
      <h1>
        <a href="https://dexscreener.com/solana/C4ht447s9fFn2KKBpqLSic9o9r4r2sSQZieeVwHWmoon" target="_blank" rel="noopener noreferrer">
          <span className="text-blue">Ground</span>
          <span className="text-white">Hawk</span>
        </a>
      </h1>
    </div>
  );
};

export default Home;
