// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  text-align: center;
  padding: 1rem;
  color: #eb7de7;
}

.people {
  font-size: 14px;
  color: #87CEEB;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".footer {\n  text-align: center;\n  padding: 1rem;\n  color: #eb7de7;\n}\n\n.people {\n  font-size: 14px;\n  color: #87CEEB;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
